import consumer from "./consumer"

consumer.subscriptions.create("RoutesReportsChannel", {
  connected() {
  },

  disconnected() {
  },

  received(data) {
    if (data.content.type == 'routes_report_published') {
      $(`#routes_report_${data.content.id}`).find("div.report_url").html(
        `<a target='_blank' class='action-btn' href='${data.content.report_url}'>Open</a>`
      );
      $(`#routes_report_${data.content.id}`).find("td.report_gif")
        .find('div.btn-group').removeClass('d-none').end();
      toastr.success('Report published successfully');
    } else if (data.content.type == 'routes_report_error') {
      toastr.error(data.content.error, `Routes Report: ${data.content.title}`);
      $(`#routes_report_${data.content.id}`).find("div.report_url").html(
        ``
      );
      $(`#routes_report_${data.content.id}`).find("div.report_url").remove();
    } else if (data.content.type == 'routes_report_gif_start') {
      $(`#routes_report_${data.content.id}`).find("td.report_gif").find('a').hide();
      $(`#routes_report_${data.content.id}`).find("td.report_gif").append(
        `<div class='spinner-border text-primary' role='status'><span class='sr-only'>Loading...</span></div>`
      );
    } else if (data.content.type == 'routes_report_gif') {
      $(`#routes_report_${data.content.id}`).find("td.report_gif")
        .find('div.spinner-border').remove().end()
        .find('a').show();
      
      const animationFileName = data.content.name;
      if (data.content.s3_key !== null) {
        triggerFileDownload(animationFileName, data.content.s3_key);
      } else {
        const blob = b64toBlob(data.content.file, 'image/gif');
        const url = URL.createObjectURL(blob);
        triggerFileDownload(animationFileName, url);
      }
    } else if (data.content.type == 'routes_report_gif_error') {
      toastr.error('Error generating animation', `Routes Report: ${data.content.title}`)
    }
  }
});


const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, {type: contentType});
  return blob;
}

function triggerFileDownload(filename, url) {
  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}