import consumer from "./consumer"

consumer.subscriptions.create("ClusterReportsChannel", {
  connected() {
  },

  disconnected() {
  },

  received(data) {
    if (data.content.type == 'cluster_report_published') {
      $(`#cluster_report_${data.content.id}`).find("div.report_url").html(
        `<a target='_blank' class='action-btn' href='${data.content.report_url}'>Open</a>`
      );
      toastr.success('Report published successfully');
    } else if (data.content.type == 'cluster_report_attached') {
      Turbolinks.visit(location.toString());
    } else if (data.content.type == 'cluster_report_error') {
      toastr.error(data.content.error, `Cluster Report: ${data.content.title}`);
      $(`#cluster_report_${data.content.id}`).find("div.report_url").html(
        ``
      );
    }
  }
});
